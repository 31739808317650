.ArticleBody {
  h2 {
    position: relative;
    margin: 80px 0 24px;
    padding-left: 1.125rem;
    font-size: 1.375rem;
    line-height: 1.4;
    letter-spacing: 2px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      border-radius: 2px;
      background-color: #ffd900;
    }

    @include mq_sm {
      margin-top: 72px;
      font-size: 1.25rem;
      line-height: 1.3;
      letter-spacing: 1.8px;
    }
  }

  h3 {
    margin: 40px 0 16px;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0;

    @include mq_sm {
      font-size: 1.125rem;
      line-height: 1.4;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-top: 32px;
    letter-spacing: 0.00735em;
    font-weight: 700;
    margin-bottom: 16px;

    @include mq_sm {
      font-size: 1rem;
      line-height: 1.5;
      margin-top: 24px;
    }
  }

  p {
    margin: 0 0 1rem;
    color: #555;
    line-height: 2;
    letter-spacing: 1.6px;
    overflow-wrap: break-word;

    @include mq_sm {
      font-size: 0.875rem;
      line-height: 1.7;
      letter-spacing: 1.4px;
    }
  }

  a {
    color: #000;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
  }

  ul {
    margin: 1.5rem 0;
    padding-left: 0px;
    list-style: none;
    li {
      position: relative;
      margin-top: 0.75rem;
      padding-left: 1rem;
      &:first-child {
        margin-top: 0;
      }
      &::before {
        position: absolute;
        top: 0.7em;
        left: 0;
        content: '';
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: #545454;
      }
      & > p {
        margin: 0;
      }

      @include mq_sm {
        &::before {
          top: 0.5em;
        }
      }
    }
  }

  ol {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
    li {
      position: relative;
      margin-top: 0.75rem;
      letter-spacing: 1.6px;
      color: #555;
      &:first-child {
        margin-top: 0;
      }
      & > p {
        margin: 0;
      }

      @include mq_sm {
        font-size: 0.875rem;
        letter-spacing: 1.4px;
      }
    }
  }

  figure {
    margin: 24px 0;
    padding: 40px;
    background-color: #f5f7fa;
    border-radius: 8px;
    text-align: center;

    @include mq_sm {
      padding: 24px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  table {
    width: 100%;

    td {
      font-size: 1rem;
      line-height: 2;
      letter-spacing: 1.6px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      text-align: left;
      padding: 16px;
      color: rgba(0, 0, 0, 0.87);

      @include mq_sm {
        font-size: 0.875rem;
      }

      &:first-of-type {
        width: 10em;
      }
    }
  }

  .ActionButton {
    display: block;
    margin: 48px auto 64px;
    position: relative;
    outline: 0;
    border: 0;
    cursor: pointer;
    user-select: none;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    min-width: 64px;
    padding: 16px 32px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffd900;
    font-weight: 700;
    font-size: 1.125rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: rgb(178, 151, 0);
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
      pointer-events: none;
      cursor: default;
    }
  }
}
