html {
  scroll-behavior: smooth;
}

.MuiDivider-light {
  border-color: #dadada;
}

@media (min-width: 541px) {
  .hidden-md {
    display: none;
  }
}

/**
 * @deprecated コンポーネント側で定義する
 * コンテントフル移行が完了し次第削除
 */
.rt-table tr > th:first-of-type,
.rt-table tr > td:first-of-type {
  width: 10em;
}

$breakpoint-sm: 600px;
$breakpoint-md: 729px;
$breakpoint-lg: 900px;

@media (min-width: $breakpoint-md) {
  br.responsive {
    display: none;
  }
}

@mixin mq_sm {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@import './article.scss';
